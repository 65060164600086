exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-calificaciones-jsx": () => import("./../../../src/templates/calificaciones.jsx" /* webpackChunkName: "component---src-templates-calificaciones-jsx" */),
  "component---src-templates-capacitaciones-js": () => import("./../../../src/templates/capacitaciones.js" /* webpackChunkName: "component---src-templates-capacitaciones-js" */),
  "component---src-templates-clientes-js": () => import("./../../../src/templates/clientes.js" /* webpackChunkName: "component---src-templates-clientes-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-evaluaciones-js": () => import("./../../../src/templates/evaluaciones.js" /* webpackChunkName: "component---src-templates-evaluaciones-js" */),
  "component---src-templates-gracias-js": () => import("./../../../src/templates/gracias.js" /* webpackChunkName: "component---src-templates-gracias-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-institucional-js": () => import("./../../../src/templates/institucional.js" /* webpackChunkName: "component---src-templates-institucional-js" */),
  "component---src-templates-noticia-js": () => import("./../../../src/templates/noticia.js" /* webpackChunkName: "component---src-templates-noticia-js" */),
  "component---src-templates-noticias-js": () => import("./../../../src/templates/noticias.js" /* webpackChunkName: "component---src-templates-noticias-js" */),
  "component---src-templates-publicacion-js": () => import("./../../../src/templates/publicacion.js" /* webpackChunkName: "component---src-templates-publicacion-js" */),
  "component---src-templates-publicaciones-js": () => import("./../../../src/templates/publicaciones.js" /* webpackChunkName: "component---src-templates-publicaciones-js" */),
  "component---src-templates-research-js": () => import("./../../../src/templates/research.js" /* webpackChunkName: "component---src-templates-research-js" */),
  "component---src-templates-riesgos-js": () => import("./../../../src/templates/riesgos.js" /* webpackChunkName: "component---src-templates-riesgos-js" */),
  "component---src-templates-social-js": () => import("./../../../src/templates/social.js" /* webpackChunkName: "component---src-templates-social-js" */)
}

